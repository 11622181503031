import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import emailjs from "emailjs-com"
import Layout from "../components/layout/"
import Seo from "../components/seo"
import { formName, btn, btnTwitter, btnMedium, btnGithub, btnLinkedin, btnDev } from "../styles/contact.module.css"
import ghIcon from "../images/socialMediaIcons/githubIcon.svg"
import liIcon from "../images/socialMediaIcons/linkedinIcon.svg"
import ttIcon from "../images/socialMediaIcons/twitterIcon.svg"
import dtIcon from "../images/socialMediaIcons/devtoIcon.svg"
import mdIcon from "../images/socialMediaIcons/mediumIcon.svg"

const ContactPage = () => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    document.addEventListener('input', function (event) {
      if (event.target.tagName.toLowerCase() !== 'textarea') return;
      autoExpand(event.target);
    }, false);

    window.addEventListener('load', function (event) {
      let textareas = event.target.getElementsByTagName('textarea');
      for (let textarea of textareas) {
        autoExpand(textarea);
      }
    })

    let globalResizeTimer = null;

    window.addEventListener('resize', function (event) {
      if (globalResizeTimer != null) {
        window.clearTimeout(globalResizeTimer);
      }
      globalResizeTimer = window.setTimeout(function () {
        let textareas = event.target.document.getElementsByTagName('textarea');
        for (let textarea of textareas) {
          autoExpand(textarea);
        }
      }, 200);
    });
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSuccess(false);
    setError(false);

    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    const templateParams = {
      user_name: name,
      user_email: email,
      message: message,
    }
    emailjs.send('contact_service', 'contact_form', templateParams, process.env.GATSBY_EMAILJS_USER_ID)
      .then((result) => {
        resetForm();
        setSuccess(true);
      }, (error) => {
        setError(true);
        setErrMsg(error.text);
      });
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    let textareas = document.getElementsByTagName('textarea');
    for (let textarea of textareas) {
      autoExpand(textarea);
    }
    setValidated(false);
  }

  var autoExpand = function (field) {
    field.style.height = 'inherit';
    var computed = window.getComputedStyle(field);
    var height = parseInt(computed.getPropertyValue('border-top-width'), 10) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    field.style.height = height + 'px';
  };

  return (
    <Layout>
      <Container fluid className="text-white my-5 min-vh-100">
        <Row className="justify-content-center align-content-center">
          <Col xs={{ span: 10, offset: 2 }} sm={{ span: 11, offset: 1 }} md={{ span: 9, offset: 1 }} className="px-3">
            <h1>Let's chat</h1>
            <p className="text-justify">
              If you would like to reach out to me with any questions, talk about collaborating on a project, or just to say hi,
              fill out the quick form below with your contact information and a short message
            </p>
            {success &&
              <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
                <Alert.Heading className="text-center m-0">Your message has been successfully submitted</Alert.Heading>
              </Alert>
            }
            {error &&
              <Alert variant="danger" onClose={() => setError(false)} dismissible>
                <Alert.Heading className="text-center m-0">
                  Your message was not sent. Please message me directly at cory.dorfner@gmail.com and include the following error message
                </Alert.Heading>
                <p>{errMsg}</p>
              </Alert>
            }
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="mb-5">
              <Row>
                <Form.Group as={Col} md="6" className="pe-md-1 mb-3" controlId="name">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter your name here"
                    name="user_name"
                    value={name}
                    className={formName}
                    onChange={event => setName(event.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please enter your name</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" className="ps-md-2 mb-3" controlId="email">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter your email here"
                    name="user_email"
                    value={email}
                    className={formName}
                    onChange={event => setEmail(event.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} xs="12" controlId="message">
                  <Form.Control
                    required
                    as="textarea"
                    rows={1}
                    placeholder="Talk to me"
                    name="message"
                    value={message}
                    className={formName}
                    onChange={event => setMessage(event.target.value)}
                    spellCheck="true"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter some information about your message
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-center justify-content-md-start">
                <Button variant="primary" type="submit" value="Send">Submit form</Button>
              </div>
            </Form>
            <Row className="justify-content-center justify-content-xl-between">
              <Col xs={12}>
                <h3>Get Social With Me</h3>
                <p className="text-justify">Follow me on my social media profiles to find out more about who I am and follow me on my journey as a full-stack developer</p>
              </Col>
              <Col xs={12} sm={6} md={4} xl={2} className="mb-4 text-center">
                <OutboundLink href="https://twitter.com/cdorfner" target="_blank" rel="noopener noreferrer">
                  <Button className={[btnTwitter, btn]}>
                    <img alt="Twitter Icon" src={ttIcon} width={24} height={24} className="mr-2" />
                    Twitter
                  </Button>
                </OutboundLink>
              </Col>
              <Col xs={12} sm={6} md={4} xl={2} className="mb-4 text-center">
                <OutboundLink href="https://linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=cory-dorfner" target="_blank" rel="noopener noreferrer">
                  <Button className={[btnLinkedin, btn]}>
                    <img alt="LinkedIn Icon" src={liIcon} width={20} height={20} className="mr-2" />
                    LinkedIn
                  </Button>
                </OutboundLink>
              </Col>
              <Col xs={12} sm={6} md={4} xl={2} className="mb-4 text-center">
                <OutboundLink href="https://github.com/dorf8839" target="_blank" rel="noopener noreferrer">
                  <Button className={[btnGithub, btn]}>
                    <img alt="GitHub Icon" src={ghIcon} width={20} height={20} className="mr-2" />
                    GitHub
                  </Button>
                </OutboundLink>
              </Col>
              <Col xs={12} sm={6} md={4} xl={2} className="mb-4 text-center">
                <OutboundLink href="https://dev.to/dorf8839" target="_blank" rel="noopener noreferrer">
                  <Button className={[btnDev, btn]}>
                    <img alt="Dev.to Icon" src={dtIcon} width={30} height={30} className="mr-2" />
                    Dev.to
                  </Button>
                </OutboundLink>
              </Col>
              <Col xs={12} sm={6} md={4} xl={2} className="mb-4 text-center">
                <OutboundLink href="https://medium.com/@cory.dorfner" target="_blank" rel="noopener noreferrer">
                  <Button className={[btnMedium, btn]}>
                    <img alt="Medium Icon" src={mdIcon} width={24} height={24} className="mr-2" />
                    Medium
                  </Button>
                </OutboundLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const Head = (props) => {
  const pageSeo = {
    title: "Contact",
    pathname: props.location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo} />
  )
}

export default ContactPage